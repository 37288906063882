import { createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  download: {
    backgroundImage: `url('/patternSimpleText.svg')`,
    backgroundColor: "#397DB7",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
      backgroundPosition: "100%",
      backgroundSize: "cover",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    "& .title": {
      color: "#fff",
      marginBottom: theme.spacing(3),
      textAlign: "center",
      textTransform: "uppercase",
    },
    "& .subtitle p": {
      color: "#fff",
      fontWeight: 700,
      lineHeight: 1.5,
      textAlign: "center",
      letterSpacing: 1.1,
      fontSize: 16,
      margin: 0,
    },
    "& .downloadApp": {
      display: "flex",
      marginTop: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        display: "block",
        marginTop: theme.spacing(2),
      },

      "& a": {
        width: 269,
        height: 80,
        margin: theme.spacing(0, 2),
        [theme.breakpoints.down("sm")]: {
          display: "block",
          width: 239,
          height: 72,
          margin: theme.spacing(2, 0),
        },
        [theme.breakpoints.down("xs")]: {
          display: "block",
          width: 209,
          height: 64,
        },
        "& img": {
          height: "100%",
          width: "100%",
          borderRadius: 6,
        },
      },
    },
    "& .leanrMoreBtn": {
      marginTop: theme.spacing(4),
      backgroundColor: "#282843",
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(3)
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(2)
      },
      "&:hover": {
        backgroundColor: "#3a3a5d",
      },
    },
  },
});

export default styles;
