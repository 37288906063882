import * as Sentry from "@sentry/nextjs";

//with a generic "Logger" service, we make the process of swapping out logging frameworks much easier in future
// for instance if sentry isn't fulfilling our needs, we would just swap it out once in this file
// rather than having to go through the whole project
const Logger = {

    logTrace: (message:string, tags?: any)=> {

        if (process.env.VERCEL_ENV === "development"){
            console.info("Trace message",message)
            if (tags){
                console.info("Trace tags",tags)
            }
        }
    },
    logMessage: (message: string, tags?: any)=> {
         
        if(process.env.VERCEL_ENV !== "development"){
            if (tags){
                Sentry.captureMessage(message, { tags: tags, level: Sentry.Severity.Info });
            }else{
                Sentry.captureMessage(message, { level: Sentry.Severity.Info });
            }
        }else{
            console.error("DEBUG msg",message)
            if (tags){
                console.info("DEBUG tags",tags)
            }
        }
    },
    logError: (error: Error, tags?:any)=> {

        if(process.env.VERCEL_ENV !== "development"){
            
            if (tags){
                Sentry.captureException(error, { tags: tags });
            }else{
                Sentry.captureException(error);
            }
        
        }else{
            console.error("DEBUG ERROR",error)
            if (tags){
                console.info("DEBUG ERROR tags",tags)
            }
            
        }
    }
}

export default Logger