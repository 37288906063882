


/** Example file */

import Prismic from 'prismic-javascript'
import Link from 'next/link'

import smConfig from './sm.json'

export const apiEndpoint = smConfig.apiEndpoint

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = ''

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
  if (doc.type === 'page' && doc.uid === 'homepage') {
    return `/`
  }

  if (doc.type === 'page') {
    return `/${doc.uid}`
  }

  if (doc.type === 'project') {
    return `/project/${doc.uid}`
  }

  if (doc.type === 'blog_post') {
    return `/blog/${doc.uid}`
  }
  if (doc.type === 'event') {
    return `/event/${doc.uid}`
  }
  if (doc.type === 'project_category') {
    return `/projects/category/${doc.uid}`
  }
  if (doc.type === 'blog_topic') {
    if(!doc.idx){
    return `/blog/topic/${doc.uid}/1`
    }
    return `/blog/topic/${doc.uid}/${doc.idx}`
  }
  if (doc.type === 'person') {
    return `/${doc.data.person_type.uid}/${doc.uid}`
  }
  if (doc.type === 'learn') {
    if(!doc.idx || doc.idx === 1){
      return `/blog`
    }
    return `/blog/page/${doc.idx}`
  }
  return '/'
}

// Additional helper function for Next/Link components
export const hrefResolver = (doc) => {
  if (doc.type === 'page' && doc.uid === 'homepage') {
    return '/'
  }

  if (doc.type === 'page') {
    return '/[uid]'
  }

  if (doc.type === 'project') {
    return '/project/[uid]'
  }

  if (doc.type === 'blog_post') {
    return '/blog/[uid]'
  }
  if (doc.type === 'event') {
    return '/event/[uid]'
  }
  if (doc.type === 'project_category') {
    return '/projects/category/[uid]'
  }
  if (doc.type === 'blog_topic') {
    return '/blog/topic/[category]/[uid]'
  }
  if (doc.type === 'person') {
    return `/${doc.data.person_type.uid}/[uid]`
  }
  if (doc.type === 'learn') {
    if(!doc.idx || doc.idx === 1){
      return `/blog`
    }
    return '/blog/page/[idx]'
  }
  return '/'
}

export const customLink = (type, element, content, children, index) => (
  <Link
    key={index}
    href={hrefResolver(element.data)}
    as={linkResolver(element.data)}
  >
    <a>{content}</a>
  </Link>
)

export const Router = {
  routes: [{"type":"page","path":"/:uid"}],

  href: (type) => {
    const route = Router.routes.find(r => r.type === type);
    return route && route.href;
  }
};

export const Client = (req = null, options = {}) => (
  Prismic.client(apiEndpoint, Object.assign({ routes: Router.routes }, options))
)