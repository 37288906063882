import { createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  footer: {
    backgroundColor: "#242440",
  },
  footerTop: {
    display: "flex",
    paddingTop: theme.spacing(10),
    borderBottom: "1px solid rgba(80, 77, 109, 0.2)",
    paddingBottom: theme.spacing(9),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(8),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(7),
    },

    "& .socialIcon": {
      flex: 0.1,
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        flex: 1,
      },
      "& .icon": {
        width: 30,
        height: 30,
        backgroundColor: "#fff",
        margin: "0px auto",
        "&:hover": {
          backgroundColor: "#9ad455",
        },
        [theme.breakpoints.down("xs")]: {
          width: 20,
          height: 20,
        },
      },
    },
    "& .linksSections": {
      marginTop: theme.spacing(8),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(7),
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(6),
      },
      "& .link": {
        "&:hover": {
          color: "#9ad455",
        },
      },
    },
    "& .footerTitle": {
      fontFamily: "Raleway,Assistant",
      fontWeight: 900,
      textAlign: "center",
      color: "#ffffff",
      textTransform: "uppercase",
    },
    "& .title": {
      color: "#ffffff",
      textTransform: "uppercase",
      marginBottom: theme.spacing(1),
      fontWeight: 700,
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },
    "& .address p": {
      marginTop: 8,
      marginBottom: 8,
      color: "#ffffff",
      fontSize: 14,
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: 13,
      },
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
        fontSize: 12,
      },
      "& a": {
        "&:hover": {
          color: "#9ad455",
        },
      },
    },
    "& a": {
      color: "#ffffff",
      fontFamily: "Raleway,Assistant",
      margin: theme.spacing(1, 0),
      fontWeight: 500,
      textTransform: "uppercase",
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },
  },
  socialWrapper: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4),
    },
  },
  footerBottom: {
    padding: theme.spacing(6, 2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5, 2),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4, 2),
    },
    "& .logoImg": {
      maxWidth: 124,
      cursor: "pointer",
      margin: theme.spacing(4, 0),
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(2, 0),
      },
    },
    "& .input": {
      width: 332,
      borderRadius: 6,
      backgroundColor: "#34345a",
      color: "#fff",
      marginBottom: theme.spacing(3.5),
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      "& .MuiSelect-select": {
        backgroundColor: '#34345a',
      },
      "& .MuiSelect-select.Mui-disabled": {
        cursor: "pointer !important",
      },
      "& input": {
        fontWeight: 500,
        color: "#89899d",
      },
      "& fieldset": {
        border: 0,
      },
      "& svg": {
        color: "#fff",
        borderRadius: 6,
        backgroundColor: "#4a92cf",
      },
    },

    "& .privacyLink": {
      marginBottom: 16,
      "& .footerLinks": {
        textDecoration: "none",
        color: "#fff",
        fontFamily: "Raleway,Assistant",
        marginBottom: 16,
        cursor: "pointer",
        "&:hover": {
          color: "#9ad455",
        },
        "& p": {
          color: "#fff",
          fontFamily: "Raleway,Assistant",
          cursor: "pointer",
          "&:hover": {
            color: "#9ad455",
          },
        },
      },
      "& :first-child": {
        borderRight: theme.direction === "ltr" ? "1px solid #fff" : 0,
        borderLeft: theme.direction === "ltr" ? 0 : "1px solid #fff",
        paddingRight: theme.direction === "ltr" ? 8 : 0,
        marginRight: theme.direction === "ltr" ? 8 : 0,
        paddingLeft: theme.direction === "ltr" ? 0 : 8,
        marginLeft: theme.direction === "ltr" ? 0 : 8,
      },
    },
    "& .footerBottomLink": {
      textDecoration: "none",
      color: "#fff",
      fontFamily: "Raleway,Assistant",
      marginBottom: 16,
      cursor: "pointer",
      "&:hover": {
        color: "#9ad455",
      },
    },
  },
  formControl: {
    width: 300,
  },
  dialogCust: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(0),
        width: "100%",
        margin: theme.spacing(2),
      },
    },
  },
  selectLanguage: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
    minWidth: 394,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
      minWidth: "unset",
    },
    "& .dialogtitle": {
      fontFamily: "Raleway,Assistant",
      fontWeight: 900,
      letterSpacing: 1.24,
      textAlign: "center",
      color: "#4c4c78",
      textTransform: "uppercase",
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: theme.spacing(2),
      },
    },
    "& input": {
      textAlign: theme.direction === "ltr" ? "left" : "right",
    },
    "& .fieldTitle": {
      fontFamily: "Raleway,Assistant",
      fontWeight: 700,
      color: "#4c4c78",
      marginBottom: theme.spacing(1),
      textAlign: theme.direction === "ltr" ? "left" : "right",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: '#8bc249',
    },
    "& .MuiSelect-select": {
      textAlign: theme.direction === "ltr" ? "left" : "right",
    },
    "& fieldset": {
      border: '2px solid #f3f3f3',
      "&:hover": {
        border: '2px solid #8bc249',
      }
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#f3f3f3',
    },
    "& .saveBtn": {
      margin: "16px auto 16px",
    },
    "& .cancel": {
      cursor: "pointer",
      fontFamily: "Raleway,Assistant",
      lineHeight: 1.71,
      textAlign: "center",
      color: "#4c4c78",
      textDecoration: "underline",
    },
  },
  autocompletePaper:{
    '& .MuiAutocomplete-option':{
      direction:theme.direction === "ltr" ? "ltr" : "rtl",
    }
  },
  selectMenuItem:{
    direction:theme.direction === "ltr" ? "ltr" : "rtl",
  },
  codeWrapper: {
    display: 'flex',
    "& span": {
      marginRight: 4,
    }
  },
});

export default styles;
