import React from "react";
import PropTypes from "prop-types";
import SubHeader from "../SubHeader";
import Header from "../Header";
import DownloadApp from "../DownloadApp";
import NewsUpdate from "../NewsUpdate";
import Footer from "../Footer";
import { useLayout } from "../../utils/layout-context";
import ExitPreviewButton from '../ExitPreview';
import { ClassNames } from "@emotion/core";
import { position } from "polished";

const Layout = ({ children, locale }) => {
  const { layout } = useLayout();

  return (
    <div>
      <div>
        {layout.enabled___disabled && <SubHeader />}
        <Header />
      </div>
      {children}
      <DownloadApp hideLearnMoreButton={children?.props?.data?.hide_mobile_app_download_learn_more_button || false} />
      <NewsUpdate />
      <Footer localeState={locale} />
      <ExitPreviewButton />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
