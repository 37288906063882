import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useLayout } from "../../utils/layout-context";
import CustomLink from "../CustomLink";
import style from "./style";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

const DownloadApp = ({ classes, hideLearnMoreButton }: { classes: any, hideLearnMoreButton: boolean }) => {
  const { layout } = useLayout();
  return (
    <div className={classes.download}>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Typography variant="h3" className="title">
              {RichText.asText(layout.download_app_title)}
            </Typography>
            <div className="subtitle">
              {RichText.render(layout.download_app_description)}
            </div>
            <div className="downloadApp">
              <CustomLink
                key={"appStore"}
                link={layout.apple_download_link}
                className="appStore"
              >
                <img src={layout.apple_download_image.url} alt="app Play" />
              </CustomLink>

              <CustomLink
                key={"playStore"}
                link={layout.android_download_link}
                className="playStore"
              >
                <img src={layout.android_download_image.url} alt="google Play" />
              </CustomLink>
            </div>
            {!hideLearnMoreButton && <CustomLink
              key={"learnMoreButton"}
              link={layout.learn_more_button_link}
              className="learnMoreButton"
            >
              <Button
                variant="contained"
                color="primary"
                className="leanrMoreBtn"
              >
                {RichText.asText(layout.learn_more_button_text)}
              </Button>
            </CustomLink>}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

DownloadApp.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(DownloadApp);
