import { action, Action, createStore, Store } from 'easy-peasy';
import { createTypedHooks } from 'easy-peasy';
import CountryConfig from '../models/braintree/CountryConfig';
import { CurrencyCode } from '../models/braintree/DonationModels';
import allCountries from "../utils/countries";
import { persist } from 'easy-peasy';
import { Currency } from '../services/currency-service';
import { customLink } from '../prismic1';

interface StoreModel {
  country: CountryConfig
  setCountry: Action<StoreModel, string>;
  currency: Currency
  setCurrency: Action<StoreModel, CurrencyCode>;
  customLinkClicked: number;
  setCustomLinkClicked: Action<StoreModel>;
}

const store = createStore<StoreModel>(
  persist(
    {
      customLinkClicked: 0,
      setCustomLinkClicked: action((state) => {
        state.customLinkClicked = state.customLinkClicked + 1;
      }),
      country: null,
      setCountry: action((state, payload) => {
        state.country = allCountries.find(x => x.code == payload);
      }),
      currency: Currency.get(CurrencyCode.USDollar),
      setCurrency: action((state, payload) => {
        state.currency = Currency.get(payload)
      })
    }
    , { storage: 'localStorage',deny:['customLinkClicked'] }
  )
);

const typedHooks = createTypedHooks<StoreModel>();

export const useVFIStoreActions = typedHooks.useStoreActions;
export const useVfIStoreDispatch = typedHooks.useStoreDispatch;
export const useVFIStoreState = typedHooks.useStoreState;

export default store