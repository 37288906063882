const styles = (theme) => ({
  headerBg: {
    backgroundImage: `url('/headertopbg.png')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    zIndex: 9,
    position: 'absolute',
    width: '100%',
  },
  header: {
    display: "flex",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    "& .logo": {
      width: 123,
      [theme.breakpoints.down("sm")]: {
        width: 103,
      },
      [theme.breakpoints.down("xs")]: {
        width: 93,
        marginLeft: 0,
      },
    },
    "& img": {
      marginLeft: theme.spacing(2),
      display: "block",
    },
    "& .menu": {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      "& a.menuLink": {
        cursor: "pointer",
        color: "#fff",
        fontFamily: "Raleway,Assistant",
        fontSize: 14,
        fontWeight: 800,
        margin: theme.spacing(0, 4),
        textDecoration: "none",
        textTransform: "uppercase",
      },
      "& a.dark": {
        color: "#4c4c78",
      },
      "& button": {
        fontSize: 14,
        width: "fit-Content",
        minWidth: "unset",
        marginLeft: theme.spacing(2),
        width: 88,
        borderRadius: 4,
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    "& .mobileMenu": {
      flex: 1,
      justifyContent: "flex-end",
      alignItems: "center",
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
      },
      "& .drawerBtnWhite": {
        color: "#fff",
      },
      "& .drawerBtnBlue": {
        color: "#3a3a5d",
      },
    },
  },
  drawerMenu: {
    "& a": {
      color: "#4c4c78",
      fontFamily: "Raleway,Assistant",
      fontSize: 14,
      fontWeight: 800,
      marginBottom: theme.spacing(2),
    },
  },
  sidebar: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "& .list": {
      flex: 1,
      paddingRight: theme.spacing(2),
    },
    "& .button": {
      padding: theme.spacing(2),
    },
  },
});

export default styles;
