import { Snackbar } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

/* For use in material-ui/src/styles/colors.js */
export const primary = {
  50: '#f1f8ea',
  100: '#ddedc9',
  200: '#c6e1a6',
  300: '#afd582',
  400: '#9ecc67',
  500: '#8dc34c',
  600: '#85bd45',
  700: '#7ab53c',
  800: '#70ae33',
  900: '#5da124',
  A100: '#ecffde',
  A200: '#cfffab',
  A400: '#b2ff78',
  A700: '#a4ff5e',
  contrastDefaultColor: 'dark',
};

export const secondary = {
  50: '#e9f2f9',
  100: '#c9def1',
  200: '#a5c9e7',
  300: '#80b3dd',
  400: '#65a2d6',
  500: '#4a92cf',
  600: '#438aca',
  700: '#3a7fc3',
  800: '#3275bd',
  900: '#2263b2',
  A100: '#edf5ff',
  A200: '#bad7ff',
  A400: '#87baff',
  A700: '#6eabff',
  contrastDefaultColor: 'dark',
};

const defaultTheme = createMuiTheme({});

const { breakpoints } = defaultTheme;

const theme = createMuiTheme({
  ...defaultTheme,
  typography: {
    fontFamily: [
      'Raleway',
      'Assistant',
      'Arial',
      'Nunito',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    useNextVariants: true,
  },
  palette: {
    primary,
    secondary,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.grecaptcha-badge': { visibility: 'hidden' },
        body: {
          fontFamily: 'Raleway,Assistant,sans-serif',
          backgroundColor: '#fff',
        },
        h1: {
          fontFamily: 'Raleway,Assistant,sans-serif',
          fontSize: 48,
          fontWeight: 900,
          margin: 0,
          [breakpoints.down('sm')]: {
            fontSize: 44,
          },
          [breakpoints.down('xs')]: {
            fontSize: 40,
          },
        },
        h2: {
          fontFamily: 'Raleway,Assistant,sans-serif',
          fontSize: 40,
          fontWeight: 900,
          margin: 0,
          [breakpoints.down('sm')]: {
            fontSize: 36,
          },
          [breakpoints.down('xs')]: {
            fontSize: 32,
          },
        },
        h3: {
          fontFamily: 'Raleway,Assistant,sans-serif',
          fontSize: 36,
          fontWeight: 900,
          margin: 0,
          [breakpoints.down('sm')]: {
            fontSize: 32,
          },
          [breakpoints.down('xs')]: {
            fontSize: 28,
          },
        },
        h4: {
          fontFamily: 'Raleway,Assistant,sans-serif',
          fontSize: 28,
          fontWeight: 900,
          margin: 0,
          [breakpoints.down('sm')]: {
            fontSize: 26,
          },
          [breakpoints.down('xs')]: {
            fontSize: 24,
          },
        },
        h5: {
          fontFamily: 'Raleway,Assistant,sans-serif',
          fontSize: 24,
          fontWeight: 900,
          margin: 0,
          [breakpoints.down('sm')]: {
            fontSize: 22,
          },
          [breakpoints.down('xs')]: {
            fontSize: 20,
          },
        },
        h6: {
          fontFamily: 'Raleway,Assistant,sans-serif',
          fontSize: 18,
          fontWeight: 900,
          margin: 0,
          [breakpoints.down('sm')]: {
            fontSize: 17,
          },
          [breakpoints.down('xs')]: {
            fontSize: 16,
          },
        },
        p: {
          fontFamily: 'Raleway,Assistant,sans-serif',
          fontSize: 18,
          [breakpoints.down('sm')]: {
            fontSize: 17,
          },
          [breakpoints.down('xs')]: {
            fontSize: 16,
          },
        },
        ul: {
          fontFamily: 'Raleway,Assistant,sans-serif',
          fontSize: 18,
          [breakpoints.down('sm')]: {
            fontSize: 17,
          },
          [breakpoints.down('xs')]: {
            fontSize: 16,
          },
        },
        li: {
          fontFamily: 'Raleway,Assistant,sans-serif',
          fontSize: 18,
          [breakpoints.down('sm')]: {
            fontSize: 17,
          },
          [breakpoints.down('xs')]: {
            fontSize: 16,
          },
        },
        textarea: {
          height: 60,
        },
      },
    },
    MuiTypography: {
      h1: {
        fontSize: 48,
        fontWeight: 900,
        [breakpoints.down('sm')]: {
          fontSize: 40,
        },
        [breakpoints.down('xs')]: {
          fontSize: 32,
        },
      },
      h2: {
        fontSize: 40,
        fontWeight: 900,
        [breakpoints.down('sm')]: {
          fontSize: 34,
        },
        [breakpoints.down('xs')]: {
          fontSize: 28,
        },
      },
      h3: {
        fontSize: 36,
        fontWeight: 900,
        [breakpoints.down('sm')]: {
          fontSize: 30,
        },
        [breakpoints.down('xs')]: {
          fontSize: 24,
        },
      },
      h4: {
        fontSize: 28,
        fontWeight: 900,
        [breakpoints.down('sm')]: {
          fontSize: 24,
        },
        [breakpoints.down('xs')]: {
          fontSize: 20,
        },
      },
      h5: {
        fontSize: 24,
        fontWeight: 900,
        [breakpoints.down('sm')]: {
          fontSize: 22,
        },
        [breakpoints.down('xs')]: {
          fontSize: 18,
        },
      },
      h6: {
        fontSize: 18,
        fontWeight: 900,
        [breakpoints.down('sm')]: {
          fontSize: 17,
        },
        [breakpoints.down('xs')]: {
          fontSize: 16,
        },
      },
      subtitle1: {
        fontSize: 16,
        lineHeight: 1.5,
        [breakpoints.down('sm')]: {
          fontSize: 15,
        },
        [breakpoints.down('xs')]: {
          fontSize: 14,
        },
      },
      subtitle2: {
        fontSize: 14,
        lineHeight: 1.5,
        [breakpoints.down('sm')]: {
          fontSize: 13,
        },
        [breakpoints.down('xs')]: {
          fontSize: 12,
        },
      },
      body1: {
        fontSize: 18,
        lineHeight: 1.5,
        [breakpoints.down('sm')]: {
          fontSize: 17,
        },
        [breakpoints.down('xs')]: {
          fontSize: 16,
        },
      },
      body2: {
        fontSize: 14,
        [breakpoints.down('sm')]: {
          fontSize: 13,
        },
        [breakpoints.down('xs')]: {
          fontSize: 12,
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: 6,
        fontSize: 16,
        fontWeight: 900,
        boxShadow: 'none',
        textAlign: 'center',
        padding: '14.5px 16px',
        lineHeight: 'initial',
        textTransform: 'uppercase',
        minWidth: 170,
        // [breakpoints.down('sm')]: {
        //   fontSize: 15,
        //   padding: 12,
        // },
        // [breakpoints.down('xs')]: {
        //   fontSize: 14,
        //   padding: 8,
        // },
      },
      outlined: {
        padding: '14.5px 16px',
        borderRadius: 6,
        border: 'solid 2px #ffffff',
        color: '#fff',
        boxShadow: 'none',
        [breakpoints.down('sm')]: {
          padding: 12,
        },
        [breakpoints.down('xs')]: {
          padding: 8,
        },
        '&:hover': {
          backgroundColor: '#fff',
          border: 'solid 2px #ffffff',
          color: '#4c4c78',
          boxShadow: '0 22px 39px 0 rgba(90, 100, 148, 0.24)',
        },
        '&:active': {
          backgroundColor: '#fff',
          border: 'solid 2px #ffffff',
          color: '#4c4c78',
          boxShadow: '0 22px 39px 0 rgba(90, 100, 148, 0.24)',
        },
      },
      outlinedPrimary: {
        color: primary[500],
        border: `2px solid ${primary[500]}`,
        boxShadow: 'none',
        '&:hover': {
          color: '#fff',
          border: `2px solid ${primary[500]}`,
          boxShadow: 'none',
          backgroundColor: primary[500],
          boxShadow: '0 22px 39px 0 rgba(90, 100, 148, 0.24)',
        },
      },
      contained: {
        backgroundColor: '#fff',
        boxShadow: '0 8px 18px 0 rgba(78, 111, 128, 0.31)',
        color: '#1b3361',
        '&:hover': {
          backgroundColor: '#f9f9f9',
          color: '#263167;',
        },
        '&:active': {
          backgroundColor: '#fff',
          color: '#7681b5',
          border: '1px solid #bcc8e3',
        },
      },
      containedPrimary: {
        backgroundColor: primary[500],
        boxShadow: 'none',
        color: '#fff',
        '&:hover': {
          boxShadow: '0 22px 39px 0 rgba(90, 100, 148, 0.24)',
          backgroundColor: '#9ad455',
          color: '#fff',
        },
        '&:active': {
          boxShadow: '0 22px 39px 0 rgba(90, 100, 148, 0.24)',
          backgroundColor: '#9ad455',
          color: '#fff',
        },
      },
      containedSecondary: {
        backgroundColor: secondary[500],
        color: '#fff',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: '0 22px 39px 0 rgba(90, 100, 148, 0.24)',
          backgroundColor: '#5ea9e9',
          color: '#fff',
        },
        '&:active': {
          boxShadow: '0 22px 39px 0 rgba(90, 100, 148, 0.24)',
          backgroundColor: '#5ea9e9',
          color: '#fff',
        },
      },
      sizeSmall: {
        fontSize: 14,
        padding: '9px 9px',
        // [breakpoints.down('sm')]: {
        //   fontSize: 13,
        //   padding: 8,
        // },
        // [breakpoints.down('xs')]: {
        //   fontSize: 12,
        //   padding: 7,
        // },
      },
      sizeLarge: {
        fontSize: 18,
        padding: '19.5px 20px',
        // [breakpoints.down('sm')]: {
        //   fontSize: 16,
        //   padding: 18,
        // },
        // [breakpoints.down('xs')]: {
        //   fontSize: 14,
        //   padding: 16,
        // },
      },
    },

    MuiInputBase: {
      borderRadius: 6,
      input: {
        backgroundColor: '#f3f3f3',
        fontSize: 16,
        fontWeight: 500,
        color: '#070f37',
        borderRadius: 6,
        border: 0,
        '&::placeholder': {
          opacity: 1,
          color: '#4d4d5b',
        },
      },
      notchedOutline: {
        borderColor: '#f3f3f3',
      },
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: '#f3f3f3 !important',
      },
    },
    MuiAlert: {
      filledSuccess: {
        // backgroundColor: '#8dc34c',
        '& .MuiAlert-action': {
          display: 'none',
        },
      },
      filledError: {
        // backgroundColor: '#3a3a5d',
        '& .MuiAlert-action': {
          display: 'none',
        },
      },
      filledWarning: {
        // backgroundColor: '#3a3a5d',
        '& .MuiAlert-action': {
          display: 'none',
        },
      },
    },
  },
});

export default theme;
