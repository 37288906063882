import React, { useState, useEffect } from "react";
import Image from "next/image";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Dialog,
  FormControl,
  InputLabel,
  Paper,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { useLayout } from "../../utils/layout-context";
import CustomLink from "../CustomLink";
import style from "./style";
import { useRouter } from "next/router";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useLocality } from "../../store/locality-settings-context";
import allCountries from "../../utils/countries";
import { geoData } from "../../services/ipInfo";
import {
  setCookie,
  getCookie,
  checkCookie,
} from "../../services/locality-cookies";
import regionHelpers from "../../services/regionHelpers";
import CountryConfig from "../../models/braintree/CountryConfig";
import { CurrencyCode } from "../../models/braintree/DonationModels";
import {
  useVFIStoreState,
  useVFIStoreActions
} from "../../store/vfi-easy-peasy-store";
import { useThemeChange } from "../../utils/theme-change-context";

const Footer = ({ classes, localeState }) => {
  const state = useVFIStoreState((state) => state);
  const actions = useVFIStoreActions((actions) => actions);

  const [currencyCode, setCurrencyCode] = useState<CurrencyCode>(
    state.currency?.code
  );

  const [countryCode, setCountryCode] = useState<string>(state.country?.code);

  const [componentCountry, setComponentCountry] = useState<CountryConfig>(
    state.country
  );

  const { theme, setTheme } = useThemeChange();

  const { layout } = useLayout()

  const locality = useLocality()

  const router = useRouter()

  const [open, setOpen] = useState(false)

  const [locale, setLocale] = useState(localeState ? localeState.prismicLanguage : "en-us");

  const openLanguageSettingsDialog = () => {

    setOpen(true);
  };

  const syncUIWithStateAndClose = () => {

    setCurrencyCode(state.currency?.code)

    setCountryCode(state.country?.code)

    setComponentCountry(state.country)

    setLocale(findLocale())

    setOpen(false);
  };

  const submitLocalityData = (e) => {

    e.preventDefault();
    actions.setCountry(countryCode);
    actions.setCurrency(currencyCode);
    setCookie("NEXT_LOCALE", locale, document);
    // for certain languages, we launch the legacy site in a new browser tab
    if (layout.languages) {

      const languageConfig = layout.languages.find(x => x.language_code === locale)
      if (languageConfig && languageConfig.activate_link) {
        window.open(languageConfig.link.url)
        setOpen(false)
        return
      }
    }

    router.push("/", "/", { locale: locale.toLowerCase() })
    setOpen(false)
    setTheme({ ...theme, direction: locale === "he" ? "rtl" : "ltr" })
  };

  const getLanguageName = () => {
    let language = layout.languages.find(
      (languageObj) => languageObj.language_code == findLocale()
    );

    return language?.language_name
  }

  const findLocale = () => {

    return router.locale !== "en-gb" ? router.locale.split("-")[0] : router.locale;
  };

  const setPreferencesFromIP = () => {

    return geoData()
      .then((response) => response.json())
      .then((data) => {

        const foundCountry = allCountries.find(
          (countryObj) => countryObj.code == data.country_code
        );

        if (foundCountry) {
          actions.setCountry(foundCountry.code);
          actions.setCurrency(foundCountry.vfiCurrencyCode);
          setCurrencyCode(foundCountry.vfiCurrencyCode);
          setCountryCode(foundCountry.code);
          setComponentCountry(foundCountry);
          //todo - locality context we hopefully won't need any more, can just use state
          locality.setCountry(foundCountry.code);
        }
      });
  }

  useEffect(()=> {
    syncUIWithStateAndClose()
  },[state.currency.code])

  //EFFECTS - country
  useEffect(() => {

    if (!state.country) {
      setPreferencesFromIP()
    } else {
      setComponentCountry(allCountries.find((x) => x.code === countryCode));
      //can remove soon hopefully
      locality.setCountry(state.country.code);
    }

  }, [countryCode]);


  return (
    <div className={classes.footer}>
      <div className={classes.footerTop}>
        <Container>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} container justify="center" alignItems="center">
              <Typography variant="h3" className="footerTitle">
                {RichText.asText(layout.engage_title)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            spacing={4}
            container
            justify="center"
            alignItems="center"
            className={classes.socialWrapper}
          >
            {layout.social_media_links.map((social, index) => {
              return (
                <Grid item sm={1} key={index}>
                  <CustomLink
                    link={social.social_media_link}
                    className="socialIcon"
                    key={index}
                  >
                    <div
                      className="icon"
                      style={{
                        mask: `url(${social.social_media_icon.url}) no-repeat center`,
                        WebkitMask: `url(${social.social_media_icon.url}) no-repeat center`,
                      }}
                    />
                  </CustomLink>
                </Grid>
              );
            })}
          </Grid>
          <Grid container spacing={4} className="linksSections">
            <Grid item xs={12} sm={4} md={4} container direction="column">
              <Typography variant="subtitle1" component="h6" className="title">
                {RichText.asText(layout.quick_links_title)}
              </Typography>
              {layout.quick_links.map((link, index) => {
                return (
                  <CustomLink link={link.link} key={index} className="link">
                    <Typography variant="body2">
                      {RichText.asText(link.link_title)}
                    </Typography>
                  </CustomLink>
                );
              })}
            </Grid>
            <Grid item xs={12} sm={4} md={4} container direction="column">
              <Typography variant="subtitle1" component="h6" className="title">
                {RichText.asText(layout.get_involved_title)}
              </Typography>
              {layout.get_involved_links.map((link, index) => {
                return (
                  <CustomLink link={link.link} key={index} className="link">
                    <Typography variant="body2">
                      {RichText.asText(link.link_title)}
                    </Typography>
                  </CustomLink>
                );
              })}
            </Grid>
            <Grid item xs={12} sm={4} md={4} container direction="column">
              <Typography variant="subtitle1" component="h6" className="title">
                {RichText.asText(layout.contact_us_title)}
              </Typography>
              <div className="address">
                {layout.contact_details.map((contact, index) => {
                  return (
                    <div key={index}>
                      {RichText.render(contact.contact_details1)}
                    </div>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.footerBottom}>
        <TextField
          InputLabelProps={{
            style: { color: "#fff" },
          }}
          variant="outlined"
          select
          disabled
          className="input"
          size="small"
          onClick={() => {
            openLanguageSettingsDialog();
          }}
          label={`${state.country?.label} / ${getLanguageName()} / ${state.currency?.symbol} ${state.currency?.code}`}
        ></TextField>
        <Grid item xs={12} container justify="center" alignItems="center">
          <CustomLink
            key={"logo-image-link"}
            link={layout.nav_logo_link}
            className="footerLinks"
          >
            <img
              src={layout.logo1.url}
              alt=""
              className="logoImg" />
          </CustomLink>
        </Grid>
        <div className="privacyLink">
          {layout.privacy_and_other_links.map((item, i) => (
            <CustomLink key={i} link={item.link} className="footerLinks">
              {RichText.asText(item.link_title)}
            </CustomLink>
          ))}
        </div>
        <a
          href={layout.wli_link.url}
          target="_blank"
          rel="nofollow"
          className="footerBottomLink"
        >
          {RichText.asText(layout.wli_text)}
        </a>
      </div>
      <Dialog
        open={open}
        onBackdropClick={syncUIWithStateAndClose}
        className={classes.dialogCust}
        maxWidth="xs"
      >
        <form
          onSubmit={(e) => {
            submitLocalityData(e);
          }}
        >
          <Paper className={classes.selectLanguage}>
            <div className="dialogtitle">Locality Settings</div>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={12}>
                <InputLabel className="fieldTitle">Preferred language</InputLabel>

                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  select
                  value={locale}
                  onChange={(e) => {
                    setLocale(e.target.value)
                  }}
                >
                  {layout.languages.map((language, i) => {
                    return (
                      <MenuItem className={classes.selectMenuItem} key={i} value={language.language_code}>
                        {language.language_name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className="fieldTitle">Preferred currency</InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  select
                  value={currencyCode}
                  margin="dense"
                  onChange={(e) => {
                    setCurrencyCode(e.target.value as CurrencyCode);
                  }}
                >
                  {layout.currencies.map((currency, i) => (
                    <MenuItem className={classes.selectMenuItem} key={i} value={currency.currency_code}>
                      {currency.currency_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className="fieldTitle">Your country</InputLabel>
                <Autocomplete
                  id="country-select-demo"
                  style={{ width: "100%", cursor: "pointer" }}
                  options={allCountries}
                  classes={{
                    option: classes.option,
                    paper:classes.autocompletePaper
                  }}
                  autoHighlight
                  value={componentCountry}
                  getOptionLabel={(option) => option.label}
                  onChange={(e, v: CountryConfig) => {
                    setCountryCode(v.code);
                  }}
                  renderOption={(option) => (
                    <div className={classes.codeWrapper}>
                      <span>{regionHelpers.countryToFlag(option.code)}</span>
                      {option.label}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose a country"
                      variant="outlined"
                      margin="dense"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              className="saveBtn"
              color="primary"
              type="submit"
            >
              SAVE
            </Button>

            <a className="cancel" onClick={() => syncUIWithStateAndClose()}>
              Cancel
            </a>
          </Paper>
        </form>
      </Dialog>
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default withStyles(style)(Footer);
