import React, {useContext} from 'react'
import Theme from '../models/Theme'
const ThemeChangeContext = React.createContext<Theme>({
 theme:null,
 setTheme:null
})

export const ThemeChangeConsumer = ThemeChangeContext.Consumer

export const useThemeChange = ():Theme => {
  return useContext(ThemeChangeContext);
};

export const ThemeChangeProvider = ({ children, value }) => {
  return (
    <ThemeChangeContext.Provider value={{
        theme: value[0],
        setTheme: value[1]
    }}>
        {children}
    </ThemeChangeContext.Provider>
  )
}

export default ThemeChangeContext